import { useAccount, useMsal } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import type { IndexableType } from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import type { CreateChatCompletionRequestMessage } from "openai/resources";
import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTab } from "../contexts/tabContext";
import { ChatEntity, ChatRole, type ChatVectorIndex, MessageEntity, type SingleSuggestionOption, db } from "../db";
import { loginRequest } from "../utils/authConfig";
import { useChatId } from "./useChatId";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useNavigate } from "@tanstack/react-router";
import { useMetadata } from "./useMetadata";
import { notifications } from "@mantine/notifications";
import { MRT_ColumnFiltersState } from "mantine-react-table";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

enum GPT35 {
  TURBO = "gpt-3",
  TURBO_0301 = "gpt-3.5-turbo-0301",
}

enum GPT4 {
  BASE = "gpt-4",
  BASE_0314 = "gpt-4-0314",
  BASE_32K = "gpt-4-32k",
  BASE_32K_0314 = "gpt-4-32k-0314",
}

interface ChatMessageIncomingChunk {
  content?: string;
  role?: string;
}

interface ISendingParams {
  vector_database?: ChatVectorIndex;
  chatId?: string | IndexableType;
  content?: string;
  systemContent?: string;
}

type TokenUsage = {
  model_name: string;
  system_fingerprint: string;
  token_usage: {
    completion_tokens: number;
    prompt_tokens: number;
    total_tokens: number;
  };
};

interface OpenAIChatMessage {
  answer: string;
  suggestions: SingleSuggestionOption[];
  content: string;
  role: string;
}

interface ChatMessageToken extends OpenAIChatMessage {
  timestamp: number;
}

interface ChatMessageParams extends OpenAIChatMessage {
  timestamp?: number;
  meta?: {
    loading?: boolean;
    responseTime?: string;
    chunks?: ChatMessageToken[];
  };
}

export interface ChatMessage extends ChatMessageParams {
  responseId?: string;
  pretrained?: string;
  timestamp: number;
  meta: {
    loading: boolean;
    responseTime: string;
    chunks: ChatMessageToken[];
  };
}

interface OpenAIStreamingProps {
  apiKey?: string;
  model?: GPT35 | GPT4;
}

export const CHAT_COMPLETIONS_URL = import.meta.env.VITE_BACKEND_URL;

// Utility method for transforming a chat message that may or may not be decorated with metadata
// to a fully-fledged chat message with metadata.
const createChatMessage = ({
  content,
  role,
  ...restOfParams
}: ChatMessageParams): ChatMessage => ({
  content,
  answer: content,
  role,
  timestamp: restOfParams.timestamp ?? Date.now(),
  meta: {
    loading: false,
    responseTime: "",
    chunks: [],
    ...restOfParams.meta,
  },
});

interface ChatMessageToken extends OpenAIChatMessage {
  timestamp: number;
}

interface ChatMessageParams extends OpenAIChatMessage {
  timestamp?: number;
  meta?: {
    loading?: boolean;
    responseTime?: string;
    chunks?: ChatMessageToken[];
  };
}

export interface ChatMessage extends ChatMessageParams {
  responseId?: string;
  pretrained?: string;
  timestamp: number;
  meta: {
    loading: boolean;
    responseTime: string;
    chunks: ChatMessageToken[];
  };
}

const prepareUpdatedMessages = (messages: ChatMessage[] | undefined, newMessages: ChatMessageParams[] | undefined): ChatMessage[] => {
  return [
    ...(messages ?? []),
    ...(newMessages?.map(createChatMessage) ?? []),
    createChatMessage({
      content: "",
      role: "",
      meta: { loading: true },
    }),
  ];
};

const preparePayload = (newMessages: ChatMessageParams[] | undefined, messages: ChatMessage[] | undefined, chatId: string | undefined, account: any, metadata: Record<string, string>[] | undefined): string => {
  if (!chatId) return;
  const question = newMessages?.reverse().find((m) => m.role === "user")?.content;
  const HOW_MANY_PAST_MESSAGES = 3;
  const chat_history = messages
    ?.reduce((acc: { user_message: string; ai_message: string }[], message, i) => {
      if (message.role === "user") {
        acc.push({
          user_message: message.content,
          ai_message: messages[i + 1]?.content || "",
        });
      }
      return acc;
    }, [])
    .filter(
      (m) =>
        m.ai_message &&
        !["Failed to fetch", "Sorry but I can't answer your question."].includes(m.ai_message)
    )
    .slice(-HOW_MANY_PAST_MESSAGES);

  return JSON.stringify({
    question,
    chat_history,
    conversation_id: chatId,
    user_id: account?.homeAccountId,
    options: { debug: false },
    metadata_filter: metadata ?? [],
  }).replace('"id":"', '"key":"');
};

const prepareRequestDetails = (apiKey: string, selectedTab: string, isStreamEnabled: boolean) => {
  const transactionId = uuidv4();
  Sentry.withScope((scope) => {
    scope.setTag("transaction_id", transactionId);
  });

  const CHAT_HEADERS = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
    "X-Transaction-ID": transactionId,
    Accept: "text/event-stream",
  };

  let CHAT_ENDPOINT: string = `${CHAT_COMPLETIONS_URL}/v1/chat`;
  let queryParameters: string = ''

  if (selectedTab === "salesforce") {
    CHAT_ENDPOINT += "/salesforce";
  } else if (
    selectedTab === "field_service_reports" ||
    selectedTab === "field_service_reports_sg"
  ) {
    CHAT_ENDPOINT += "/field_service_reports";
  } else if (selectedTab === "mindmaps") {
    CHAT_ENDPOINT += "/mindmaps";
  } else if (selectedTab === "drawings") {
    CHAT_ENDPOINT += "/drawings";
    queryParameters = '?no_generation=true'
  } else if (selectedTab === "rds_reports") {
    CHAT_ENDPOINT += "/rds_reports";
  } else if (selectedTab === "support_emails") {
    CHAT_ENDPOINT += "/support_emails"
  } else if (selectedTab === "useful_info") {
    CHAT_ENDPOINT += "/useful_info"
  }
  const CHAT_ENDPOINT_STREAM: string = isStreamEnabled ? `${CHAT_ENDPOINT}/stream`.concat(queryParameters) : CHAT_ENDPOINT.concat(queryParameters);

  return { CHAT_HEADERS, CHAT_ENDPOINT_STREAM };
};

const makeApiRequest = async (CHAT_ENDPOINT_STREAM: string, CHAT_HEADERS: Record<string, string>, payload: string, instance: IPublicClientApplication) => {
  try {
    let response = await fetch(CHAT_ENDPOINT_STREAM, {
      headers: CHAT_HEADERS,
      method: "POST", 
      cache: "no-cache",
      keepalive: true,
      body: payload,
    });

    if (response.status === 401) {
      const silentTokenResponse = await instance.acquireTokenSilent(loginRequest());
      const headers = {
        ...CHAT_HEADERS,
        Authorization: `Bearer ${silentTokenResponse.accessToken}`
      };

      response = await fetch(CHAT_ENDPOINT_STREAM, {
        headers,
        method: "POST",
        cache: "no-cache", 
        keepalive: true,
        body: payload,
      });

      if (response.status === 401) {
        throw new InteractionRequiredAuthError();
      }
    }
    
    return response;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest());
    }
  }
};

const processResponse = async (response: Response, isStreamEnabled: boolean, callback?: (answer: string, error?: string) => void) => {
  let answer = "";
  let answerJson: any = {};

  if (isStreamEnabled) {
    ({ answer, answerJson } = await processStreamResponse(response, callback));
  } else {
    ({ answer, answerJson } = await processNonStreamResponse(response));
  }

  if (!answer) {
    throw new Error("No response from API");
  }
  return { answer, answerJson };
};

const processStreamResponse = async (response: Response, callback?: (answer: string, error?: string) => void) => {
  let answer = "";
  let answerJson: any = {};
  const reader = response.body?.getReader();

  while (reader) {
    const { value, done } = await reader.read();
    if (done) break;

    const lines = new TextDecoder().decode(value).split("\n");
    for (const line of lines) {
      if (line.startsWith("data: ")) {
        const jsonValue = parseJsonLine(line.slice(6));
        const updatedAnswer = updateAnswerAndJson(jsonValue, answerJson, callback);
        if (updatedAnswer) {
          answer += updatedAnswer;
          callback?.(answer);
        }
      }
    }
  }

  return { answer, answerJson };
};

const processNonStreamResponse = async (response: Response) => {
  const answerJson = await response.json();
  if (response.status !== 200) {
    throw answerJson.detail ?? new Error("API response was not OK");
  }
  const answer = answerJson?.content;
  return { answer, answerJson };
};

const parseJsonLine = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
};

const parseContent = (content: string): any => {
  return JSON.parse(content.replace(/'/g, '"')
    .replace(/:\s*True/g, ': "true"')
    .replace(/:\s*False/g, ': "false"')
    .replace(/:\s*None/g, ': "None"')
    .replace(/(\w+):\s*"([^"]+)"/g, '"$1": "$2"'));
};

const updateAnswerAndJson = (jsonValue: any, answerJson: any, callback: (answer: string, error?: string) => void) => {
  if (!jsonValue) return;

  try {
    if (jsonValue.event === "mindmaps") {
      answerJson.mindmaps = parseContent(jsonValue.content);
    }

    if (jsonValue.event === "good_solutions") {
      answerJson.good_solutions = parseContent(jsonValue.content);
    }

    if (jsonValue.event === "salesforce") {
      answerJson.salesforce = parseContent(jsonValue.content);
    }

    if (jsonValue.event === "field_service_reports") {
      answerJson.field_service_reports = parseContent(jsonValue.content);
    }

    if (jsonValue.event === "error") {
      return callback("", jsonValue.content)
    }

    if (jsonValue.event === "response_id") {
      answerJson.response_id = jsonValue.content;
    }
    if (jsonValue.event === "message") {
      return jsonValue.content;
    }
  } catch (error) {
    console.error("Error updating answer and JSON:", error);
  }
};

const prepareReturnValue = (answer: string, answerJson: any, updatedMessages: ChatMessage[], beforeTimestamp: number, afterTimestamp: number): ChatMessage => {
  const diffInSeconds = (afterTimestamp - beforeTimestamp) / 1000;
  const formattedDiff = `${diffInSeconds.toFixed(2)} sec.`;
  return {
    answer,
    responseId: answerJson?.response_id,
    content: answer,
    pretrained: answerJson?.pretrained,
    role: "user",
    timestamp: afterTimestamp,
    meta: {
      ...updatedMessages[updatedMessages.length - 1].meta,
      loading: false,
      responseTime: formattedDiff,
    },
  };
};

const handleError = (error: any, setIsloading: (value: boolean) => void, callback?: (error: string, isError: boolean) => void) => {
  setIsloading(false);
  callback?.(
    typeof error === "string" ? error : (error?.message ?? "Unknown error"),
    true
  );
};

export const useChatCompletion = (_chatId?: IndexableType) => {
  const { instance } = useMsal();
  const account = useAccount();
  const apiKey = account?.idToken;
  const chatId = useChatId() || _chatId;
  const { selectedTab } = useTab();
  const [isloading, setIsloading] = useState(false);
  const settings = useLiveQuery(async () => {
    return db.settings.where({ id: "general" }).first();
  });

  const isStreamEnabled = true;

  const messages = useLiveQuery(() => {
    if (!chatId) return [];
    return db.messages.where("chatId").equals(chatId).sortBy("createdAt");
  }, [chatId]);

  const send = useCallback(
    async (
      newMessages?: ChatMessageParams[],
      callback?: (returnValue: ChatMessage | string, isError?: boolean) => void,
      metadata?: Record<string, string>[],
      messageChatId?: string
    ) => {
      if (messages?.[messages?.length - 1]?.meta?.loading) return;

      const beforeTimestamp = Date.now();
      const updatedMessages = prepareUpdatedMessages(messages, newMessages);

      const payload = preparePayload(newMessages, messages, chatId ?? messageChatId, account, metadata);
      const { CHAT_HEADERS, CHAT_ENDPOINT_STREAM } = prepareRequestDetails(apiKey ?? "", selectedTab, isStreamEnabled);

      try {
        setIsloading(true);
        const response = await makeApiRequest(CHAT_ENDPOINT_STREAM, CHAT_HEADERS, payload, instance);
        const { answer, answerJson } = await processResponse(response, isStreamEnabled, callback as (answer: string, error?: string) => void);

        const afterTimestamp = Date.now();
        const returnValue = prepareReturnValue(answer, answerJson, updatedMessages, beforeTimestamp, afterTimestamp);

        callback?.(returnValue);
        setIsloading(false);
      } catch (error) {
        setIsloading(false);
        handleError(error, setIsloading, callback);
      }
    },
    [selectedTab, chatId, settings, messages, isloading]
  );

  const navigate = useNavigate();

  const { chatMetadata } = useMetadata();

  const sendMessage = async (messageContent: string, isResend = false, chatId?: IndexableType, metadataFilter?: { key: string; value: string }[]) => {
    let messageChatId = chatId;
    if (!messageChatId) {
      const addChat = async () => {
        const chatId = await ChatEntity._(selectedTab).add();
        messageChatId = chatId;
        navigate({ to: `/chats/${chatId}?isNew`, replace: true });
      };
      await addChat();
    }

    try {
      setIsloading(true);
      const messagesSending = await makeMessagesSendingRequest({
        chatId,
        content: messageContent,
      });
      const userMessageId = await MessageEntity._()
        .setChatId(messageChatId)
        .setRole(ChatRole.USER)
        .setContent(messageContent)
        .add();

      const assistantMessageReceivedId = await MessageEntity._()
        .setChatId(messageChatId)
        .setRole(ChatRole.ASSISTANT)
        .setContent("")
        .setRepliedId(userMessageId)
        .add();

      await send(
        messagesSending,
        async (chatMessage: ChatMessage | string, error?: string) => {
          await db.messages
            .where({ id: assistantMessageReceivedId })
            .modify((message) => {
              message.done = true;
              if (typeof chatMessage === "string")
                message.content = chatMessage;
              else {
                message.content = chatMessage.content;
                message.responseId = chatMessage.responseId;
                message.pretrained = chatMessage.pretrained;
                message.suggestions = chatMessage.suggestions;
              }
              message.hasError = Boolean(error);
              message.error = error;
            });
        },
        metadataFilter || chatMetadata,
        messageChatId,
      );
    } catch (error: any) {
      if (!error || typeof error === "string") {
        notifications.show({ title: "Error", color: "red", message: error ?? "Error Happened" });
      } else if (error?.message === "Network Error") {
        notifications.show({
          title: "Error",
          color: "red",
          message: "No internet connection.",
        });
      } else if (error instanceof Error) {
        notifications.show({ title: "Error", color: "red", message: error.message });
      } else {
        const message =
          error?.message.detail || error.response?.data?.error?.message;
        if (message) {
          notifications.show({ title: "Error", color: "red", message });
        }
      }
    } finally {
      if (window.location.href.includes("isNew")) {
        window.location.href = window.location.href.replace("?isNew", "");
      }
      db.chats.update(messageChatId, {
        updatedAt: new Date(),
        totalMessages: await db.messages.where({ chatId }).count(),
      });
    }
  };

  return { isloading, setIsloading, send, makeMessagesSendingRequest, sendMessage };
};


const makeMessagesSendingRequest = async (sending: ISendingParams) => {
  const { chatId, content, systemContent } = sending;
  // Declare two empty arrays to store chat messages
  let messagesCached: CreateChatCompletionRequestMessage[] = [];
  const messagesSending: CreateChatCompletionRequestMessage[] = [];

  // If chatId is not undefined, pull old messages from database where chatId matches and sort them by createdAt
  if (chatId) {
    messagesCached = await db.messages.where({ chatId }).sortBy("createdAt");
  }

  // If systemContent exists, push it to messagesSending array with role as 'system'
  if (systemContent && typeof systemContent === "string") {
    messagesSending.push({ role: "system", content: systemContent.trim() });
  }

  // Using spread operator combine the messages from messagesCached array (old messages from database) into messagesSending
  messagesSending.push(
    ...messagesCached.map((message) => ({
      role: message.role,
      content: message.content?.trim(),
    })),
  );

  // If content exists, add it to messagesCached array as a new user message with role as 'user'
  if (content && typeof content === "string") {
    messagesSending.push({ role: "user", content: content.trim() });
  }

  return messagesSending.filter((message) => Boolean(message.content));
};
